import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let _root = this;

export default class Parallax {
    constructor() {
        _root = this;
        this.refresh();
        // window.addEventListener('resize', () => this.setSize());

    }

    refresh() {
        this.setSize();
    }

    setSize() {
        // if (window.innerWidth >= 992) {
        //     this.startMoveElements();
        //     this.startMoveBgParallax();
        //     this.startLines();
        // }
        this.startMoveElements();
    }

    startLines() {

        let element = document.querySelectorAll('.line-animate');

        if ('undefined' != typeof element && null != element) {


            gsap.utils.toArray(".line-animate").forEach(line => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: line,
                        scrub: true
                    }
                });
                tl.to(
                    line, {
                        width: '150%',
                        ease: "none"
                    }, 0);

            });
        }

    }

    startMoveElements() {

        const sectScrollTitleAll = document.querySelectorAll('.sect-scroll-title__ttl');

        if ('undefined' != typeof sectScrollTitleAll && null != sectScrollTitleAll) {

            gsap.utils.toArray(".sect-scroll-title__ttl").forEach(layer => {

                const depth = 0.2;
                const movement = -(layer.offsetWidth * depth);

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: layer.parentElement,
                        scrub: true
                    }
                });

                tl.to(
                    layer, {
                        x: movement,
                        ease: "none"
                    }, 0);

            });

        }



        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-item").forEach(layer => {

                if (layer.dataset.depth) {
                    const depth = layer.dataset.depth;
                    const movement = -(layer.offsetHeight * depth);

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: layer.parentElement,
                            scrub: true
                        }
                    });

                    tl.to(
                        layer, {
                            y: movement,
                            ease: "none"
                        }, 0);
                }

                if (layer.dataset.rotate) {
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: layer.parentElement,
                            scrub: true
                        }
                    });
                    tl.to(
                        layer, {
                            rotation: layer.dataset.rotate,
                            transformOrigin: "center center",
                            ease: "none"
                        }, 0);
                }

                if (layer.dataset.toleft) {
                    const depth = layer.dataset.toleft;
                    const movement = -(layer.offsetWidth * depth);

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: layer.parentElement,
                            scrub: true
                        }
                    });

                    tl.to(
                        layer, {
                            x: movement,
                            ease: "none"
                        }, 0);
                }
            });

        }

    }

    startMoveBgParallax() {

        let element = document.querySelectorAll('.parallax-img__container');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-img__container .parallax-img__img").forEach(section => {
                const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section.parentElement,
                        scrub: true
                    }
                });

                tl.to(
                    section, {
                        y: -heightDiff,
                        ease: "none"
                    }, 0);
            });
        }
    }

}