import 'regenerator-runtime/runtime';

import parallax from './parallax';


// components


/*----------------------------------------------------------------------------- 
------- var
----------------------------------------------------------------------------- */





/*
 * App Class
 */
class App {
    static start() {
        return new App();
    }

    constructor() {
        App._root = this;


        document.addEventListener("DOMContentLoaded", function(event) {


            const _parallax = new parallax();


        });



    }



}

App.start();